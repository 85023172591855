import React, { FC } from "react";
import Link from "next/link";
import MaxWidth from "../../components/styles/max-width";
import styled from "styled-components";
import Button from "../../components/common/Button";

const NotFoundPage: FC<{}> = () => {
  return (
    <MaxWidth>
      <Content>
        <div>
          <h1>404</h1>
          <h2>Потерялись?</h2>
          <p>Давайте вернемся в начало!</p>
          <Link href="/">
            <a href="/">
              <Button bold primary full big>
                На главную
              </Button>
            </a>
          </Link>
        </div>
      </Content>
    </MaxWidth>
  );
};

const Content = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
  h1 {
    z-index: -1;
    position: relative;
    margin: 0 0 -0.15em;
    opacity: 0.2;
    color: #aba48b;
    font-size: calc(12rem + ((1vw - 0.3em) * 15.8333));
    line-height: 0.7;
    top: 30px;

    @media (min-width: 120em) {
      font-size: 26.25em;
    }
  }
  h2 {
    margin-bottom: 15px;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    margin: 0px 0 50px 0;
  }
  a {
    width: 45%;
  }
`;

export default NotFoundPage;
