import React, { FC } from "react";
import Link from "next/link";
import styled from "styled-components";
import { GRAY } from "../styles/constants";
import { opacityHover } from "../styles/atoms";

interface Props {
  name: string;
  href: string;
  asHref?: string;
}

const Back: FC<Props> = ({ name, href, asHref }) => {
  return (
    <Link href={asHref ? asHref : href} as={asHref ? href : undefined}>
      <BackItem className="kabel" href={href}>
        {name}
      </BackItem>
    </Link>
  );
};

const BackItem = styled.a`
  text-transform: uppercase;
  color: ${GRAY};
  ${opacityHover};

  &:before {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 15px;
    margin-right: 8px;
    margin-bottom: 2.5px;
    background: url("/static/icons/back.svg") no-repeat center;
    content: "";
  }
`;

export default Back;
