import gql from "graphql-tag";

export const GET_STATIC_PAGE = gql`
  query staticPage($slug: String!) {
    shop {
      id
      pages(where: { slug: $slug }) {
        id
        name
        slug
        body
        seo {
          title
          description
        }
      }
    }
  }
`;
