import React, { FC, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import SelectInput from "../fields/SelectInput";
import Link from "next/link";
import getUndef from "../../lib/get-undef";

interface Props {
  options?: any;
  initialOption?: string;
  onChange?: any;
}

const SliderFilter: FC<Props> = ({ options, initialOption, onChange }) => {
  const [checked, setChecked] = useState(
    initialOption ? initialOption : options[0].slug
  );
  const [widthElem, setWidthElem] = useState<string | null>(String(null));
  const [coordinateElem, setCoordinateElem] = useState<string | null>(
    initialOption ? String(null) : "10"
  );

  useEffect(() => {
    setTimeout(() => {
      const intialIndex = options.findIndex(
        ({ slug }) => slug === initialOption
      );
      const width = getUndef(
        () =>
          labelsEl.current.childNodes[initialOption ? intialIndex : 0]
            .offsetWidth
      );

      if (width) {
        setWidthElem(width);
        labelPosition(initialOption);
      }
    }, 500);
  }, []);

  const labelPosition = (option) => {
    const intialIndex = options.findIndex(({ slug }) => slug === option);
    const cordinateForToggle =
      labelsEl.current.childNodes[
        option ? intialIndex : 0
      ].getBoundingClientRect().left -
      document
        .getElementsByClassName("radio-buttons")[0]
        .getBoundingClientRect().left;
    setCoordinateElem(String(cordinateForToggle));
  };
  const labelsEl = useRef(null);
  const optionsMobile = [];

  options.map((i) =>
    optionsMobile.push({
      value: i.slug,
      name: i.name,
      params: i.params,
    })
  );

  const optionsWithLabels = optionsMobile.map(({ value, name, params }) => ({
    value,
    label: () => <MobileLabel className="kabel">{name}</MobileLabel>,
    params,
  }));

  const onChangeFilter = (slug, target) => {
    setChecked(slug);
    setWidthElem(String(target.offsetWidth));
    labelPosition(slug);
  };

  const active = options.find((item) => item.slug === checked);

  return (
    <Wrapper>
      <Buttons className="radio-buttons" ref={labelsEl}>
        {options.map((item) => (
          <div className="kabel" key={item.slug}>
            {initialOption ? (
              // <Link
              //   href={
              //     item.slug === "reviews" || item.slug === "thanks"
              //       ? `/${item.slug}`
              //       : "/[page]"
              //   }
              //   as={`/${item.slug}`}
              // >
              <LabelLink
                // href={`/${item.slug}`}
                active={item.slug === checked}
                onClick={(e) => {
                  const link = getUndef(
                    () =>
                      item.metafields.find(({ key }) => key === "link").value
                  );
                  onChangeFilter(item.slug, e.target);
                  if (onChange) {
                    onChange(item.slug, link);
                  }
                }}
              >
                {item.name}
              </LabelLink>
            ) : (
              // </Link>
              <Label
                active={item.slug === checked}
                onClick={(e) => {
                  onChangeFilter(item.slug, e.target);
                  if (onChange) {
                    onChange(item.slug);
                  }
                }}
              >
                {item.name}
              </Label>
            )}
          </div>
        ))}
        <Toggle
          active={active}
          widthElem={widthElem}
          coordinateElem={coordinateElem}
          initialOption={initialOption}
        />
      </Buttons>
      <CustomSelectInput
        isFilter={true}
        options={optionsWithLabels}
        firstActiveValue={initialOption}
        onChange={(value) => {
          if (value === "franchise") return;
          if (onChange) {
            onChange(value);
          }
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: -5px 0 20px;
`;

const Buttons = styled.div<toggleWithProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  background: #ffffff;
  border-radius: 25px;
  position: relative;
  padding: 0 10px;
  overflow: auto;
  max-width: 100%;

  @media (max-width: 630px) {
    display: none;
  }
`;

interface LabelWithProps {
  active: boolean;
}

const LabelLink = styled.a<LabelWithProps>`
  padding: 13px 25px;
  cursor: pointer;
  display: block;
  z-index: 10;
  position: relative;
  font-size: 16px;
  color: ${({ active }) => (active ? "#fff" : "#000")};
  white-space: nowrap;
`;

const Label = styled.label<LabelWithProps>`
  padding: 13px 15px;
  cursor: pointer;
  display: block;
  z-index: 10;
  position: relative;
  font-size: 16px;
  transition: 0.3s;
  color: ${({ active }) => (active ? "#fff" : "#000")};
  white-space: nowrap;
`;

const MobileLabel = styled.span`
  color: #000;
`;

interface toggleWithProps {
  active?: boolean;
  widthElem?: string;
  coordinateElem?: string;
  initialOption?: string;
}

const Toggle = styled.div<toggleWithProps>`
  left: 13px;
  top: 8px;
  background: #e61917;
  position: absolute;
  display: flex;
  align-items: center;
  background: #e61917;
  box-shadow: 0px 6px 12px rgba(230, 25, 23, 0.4);
  border-radius: 15px;
  height: 34px;
  transition: ${({ initialOption }) => (initialOption ? "none" : "0.3s")};
  z-index: 0;
  width: ${({ active, widthElem }) =>
    active && widthElem ? `${widthElem}px` : 0};
  left: ${({ active, coordinateElem }) =>
    active && coordinateElem ? `${coordinateElem}px` : 0};
`;

const CustomSelectInput = styled(SelectInput)`
  @media (min-width: 630px) {
    display: none;
  }
`;

export default SliderFilter;
