import React, { useContext } from "react";
import styled from "styled-components";
import { Query } from "react-apollo";
import MaxWidth from "../components/styles/max-width";
import Back from "../components/common/Back";
import SliderFilter from "../components/common/SliderFilter";
import getUndef from "../lib/get-undef";
import { GET_STATIC_PAGE } from "../queries/StaticPage";
import { GET_BAR } from "../queries/BarCollections";
import Spinner from "../components/common/Spinner";
import NotFoundPage from "./ErrorPages/NotFoundPage";
import {
  getBarCollections,
  getBarCollectionsVariables,
} from "../__generated__/getBarCollections";
import Router from "next/router";
import HelmetComponent from "../components/layout/HelmetComponent";
import { CityContext } from "../components/context/CityContext";

const Page = ({ data, loading, error, page }) => {
  const { cityNameForSeo } = useContext(CityContext);

  if (data.shop.pages.length === 0) {
    return <NotFoundPage />;
  }

  const pageData = getUndef(() => data.shop.pages[0]);
  const name = getUndef(() => pageData.name);
  const body = getUndef(() => pageData.body);
  const title = getUndef(() => pageData.seo.title);
  const description = getUndef(() => pageData.seo.description);

  const createMarkup = () => {
    return { __html: body };
  };

  return (
    <MaxWidth>
      <Back name="НА ГЛАВНУЮ" href="/" />
      <HelmetComponent
        title={
          title
            ? `МИКАFood - ${title} - Доставка еды в ${cityNameForSeo}`
            : undefined
        }
        description={description}
      />
      {loading ? <Spinner /> : <h1 className="h2">{name}</h1>}
      <Query<getBarCollections, getBarCollectionsVariables>
        query={GET_BAR}
        variables={{
          slug: "footer-menu",
        }}
      >
        {({ loading, error, data }) => {
          if (error || loading) {
            return null;
          }
          const navStaticPage = getUndef(
            () => data.shop.navigations[0].lineItems
          );

          return (
            <>
              {page !== "privacy-policy" ? (
                <SliderFilter
                  options={navStaticPage}
                  initialOption={page}
                  onChange={(val, href) => {
                    if (href && href.indexOf("http") >= 0) {
                      window.open(href, "_blank").focus();
                      return;
                    }
                    Router.push(
                      val === "thanks" || val === "reviews"
                        ? `/${val}`
                        : "/[page]",
                      `/${val}`
                    );
                  }}
                />
              ) : null}
            </>
          );
        }}
      </Query>
      <Article>
        <div
          className="user-content"
          dangerouslySetInnerHTML={createMarkup()}
        />
      </Article>
    </MaxWidth>
  );
};

const Article = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 40px;

  @media (max-width: 630px) {
    margin-top: 28px;
    padding: 20px;
  }

  h1 {
    margin-bottom: 20px;
  }

  div h1 {
    margin-bottom: 20px;
  }

  div {
    @media (max-width: 1570px) {
      flex-direction: column;
    }
  }

  hr {
    opacity: 0.48;
    border: none;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 3em;
    @media (max-width: 1570px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  li {
    list-style: none;

    span {
      color: #78b833;
      font-size: 55px;
      width: 30%;

      @media (max-width: 1570px) {
        width: 100%;
      }
    }

    div {
      width: 50%;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;

      @media (max-width: 1570px) {
        width: 100%;
      }
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    width: 60%;
    @media (max-width: 630px) {
      width: 100%;
    }
  }

  iframe {
    width: 600px;
    height: 500px;
    margin-left: 20px;
    @media (max-width: 1570px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .grey {
    color: gray;
    font-size: 22px;
  }
  p {
  }
`;

Page.getInitialProps = async ({
  res,
  req,
  apolloClient,
  query: { page },
}: any) => {
  const { data, loading, error } = await apolloClient.query({
    query: GET_STATIC_PAGE,
    variables: {
      slug: page,
    },
  });

  if (data.shop.pages.length === 0 && req && req.url !== "/undefined") {
    res.statusCode = 404;
  }

  return { data, loading, error, page };
};

export default Page;
